import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 3";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "create-user-landing-pages"
    }}>{`Create user landing pages`}</h1>
    <p>{`This is the meat of our app. Users creating their beautiful landing pages using nothing but markdown.`}</p>
    <p>{`We create a simple editor and leverage Gatsby's markdown rendering machinery to create static pages from our DynamoDB database using GraphQL at build time. When the page renders, we refresh its state live from the server just in case.`}</p>
    <Vimeo id="392506029" mdxType="Vimeo" />
    <h2 {...{
      "id": "a-simple-dashboard-component"
    }}>{`A simple dashboard component`}</h2>
    <Vimeo id="392506275" mdxType="Vimeo" />
    <h2 {...{
      "id": "a-create-page-form"
    }}>{`A create page form`}</h2>
    <Vimeo id="392506657" mdxType="Vimeo" />
    <h2 {...{
      "id": "createpage-mutation-on-button-click"
    }}>{`createPage mutation on button click`}</h2>
    <p>{`For better accessibility you should wrap the form in a `}<inlineCode parentName="p">{`<form>`}</inlineCode>{` tag and use the `}<inlineCode parentName="p">{`onSubmit`}</inlineCode>{` event to run your mutation. But we're hacking a basic MVP so using clicks is fine :)`}</p>
    <Vimeo id="392506932" mdxType="Vimeo" />
    <h2 {...{
      "id": "createpage-mutation-on-the-backend"
    }}>{`createPage mutation on the backend`}</h2>
    <Vimeo id="392747608" mdxType="Vimeo" />
    <h2 {...{
      "id": "make-the-pages-table"
    }}>{`Make the pages table`}</h2>
    <p>{`Also verify that page creation now works :)`}</p>
    <Vimeo id="392748048" mdxType="Vimeo" />
    <h2 {...{
      "id": "link-to-page-after-creating"
    }}>{`Link to page after creating`}</h2>
    <Vimeo id="393152465" mdxType="Vimeo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      